/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationfaq_module">
                    {{cvFaqModuleLabel}}</label
                  >
                  <input
                    v-model="vmAdminFaqFormData.faq_module"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationfaq_title">
                    {{cvFaqTitleLabel}}</label
                  >
                <vue-editor v-model="vmAdminFaqFormData.faq_title" required></vue-editor>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationfaq_description">
                    {{cvFaqDescriptionLabel}}</label
                  >
                  <vue-editor v-model="vmAdminFaqFormData.faq_description" required></vue-editor>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="AdminFaqAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { AdminFaq } from "../../../FackApi/api/AdminFaq"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import { VueEditor } from "vue2-editor"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "AdminFaqAdd",
  components: {
    VueEditor
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      apiName: "admin_faq_add",
      cvCardTitle: "Add Admin Faq",
      cvCardSubHeader: "Add Admin Faq sub header",
      cvSubmitBtn: "Add",
      cvFaqModuleLabel: "Faq module",
      cvFaqTitleLabel: "Faq title",
      cvFaqDescriptionLabel: "Faq Description",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Admin Faq Added",
      vmAdminFaqFormData: Object.assign({}, this.initFormData()),
      vmAdminFaqDesc: null,
      vmAdminFaqType: null
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "faq_module": "",
        "faq_title": "",
        "faq_description": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmAdminFaqFormData) {
          if (!this.vmAdminFaqFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * AdminFaqAdd
     */
    async AdminFaqAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let AdminFaqAddResp = await AdminFaq.AdminFaqAdd(this, this.vmAdminFaqFormData)
        await ApiResponse.responseMessageDisplay(this, AdminFaqAddResp)
        if (AdminFaqAddResp && !AdminFaqAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseAdminFaqAddModal", AdminFaqAddResp.resp_data)
        }
        this.vmAdminFaqFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at AdminFaqAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
